var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-3",attrs:{"no-body":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t("apps.transactions.purchase.goodsRecipient.singular.orderNo"),"label-for":"orderNo"}},[_c('validation-provider',{attrs:{"name":"orderNo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"purchase-order-no","options":_vm.LOV.orderNo,"reduce":function (field) { return field.id; },"label":"code","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.orderNo),callback:function ($$v) {_vm.$set(_vm.form, "orderNo", $$v)},expression:"form.orderNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.date'),"label-for":"date"}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control form-control-sm",attrs:{"id":"date","config":_vm.dateFormat,"state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.code'),"label-for":"code"}},[_c('b-form-input',{attrs:{"id":"code","size":"sm","placeholder":_vm.$t('globalSingular.auto'),"disabled":_vm.actions.isPreview},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.tags'),"label-for":"tags"}},[_c('v-select',{attrs:{"id":"tags","label":"name","multiple":"","options":_vm.LOV.tags,"reduce":function (field) { return field.id; },"searchable":!_vm.actions.isPreview,"selectable":function (option) { return !_vm.actions.isPreview; }},model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.namePenerimaBarang'),"label-for":"recipientsName"}},[_c('validation-provider',{attrs:{"name":"recipientsName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"recipientsName","size":"sm","placeholder":!_vm.actions.isPreview ? _vm.$t('globalSingular.namePenerimaBarang') : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.recipientsName),callback:function ($$v) {_vm.$set(_vm.form, "recipientsName", $$v)},expression:"form.recipientsName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"my-1"},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"12","md":"3"}},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":_vm.actions.isPreview ? 'outline-secondary' : 'primary'},on:{"click":function($event){return _vm.getSelectedOrder()}}},[_vm._v(" "+_vm._s(_vm.$t('globalActions.generate'))+" ")])],1)],1),_c('b-table',{ref:"refFormTable",staticClass:"position-relative mt-2",attrs:{"id":"refFormTable","responsive":"","no-provider-paging":"","no-provider-filtering":"","fields":_vm.tableColumns,"items":_vm.detailOrder,"primary-key":"id","show-empty":""},scopedSlots:_vm._u([{key:"head(actions)",fn:function(){return [_c('feather-icon',{staticClass:"mx-auto",attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true},{key:"cell(orderNo)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"sm","disabled":""},model:{value:(_vm.form.details[index].orderNo),callback:function ($$v) {_vm.$set(_vm.form.details[index], "orderNo", $$v)},expression:"form.details[index].orderNo"}}),_c('b-form-input',{attrs:{"hidden":true,"size":"sm","disabled":""},model:{value:(_vm.form.details[index].orderId),callback:function ($$v) {_vm.$set(_vm.form.details[index], "orderId", $$v)},expression:"form.details[index].orderId"}}),_c('b-form-input',{attrs:{"hidden":true,"size":"sm","disabled":""},model:{value:(_vm.form.details[index].orderDetailsId),callback:function ($$v) {_vm.$set(_vm.form.details[index], "orderDetailsId", $$v)},expression:"form.details[index].orderDetailsId"}})],1)]}},{key:"cell(item)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"size":"sm","disabled":""},model:{value:(_vm.form.details[index].item),callback:function ($$v) {_vm.$set(_vm.form.details[index], "item", $$v)},expression:"form.details[index].item"}}),_c('b-form-input',{attrs:{"hidden":true,"size":"sm","disabled":""},model:{value:(_vm.form.details[index].itemId),callback:function ($$v) {_vm.$set(_vm.form.details[index], "itemId", $$v)},expression:"form.details[index].itemId"}})],1)]}},{key:"cell(quantityOrder)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('cleave',{key:index,staticClass:"form-control text-right form-control-sm",attrs:{"options":_vm.numeric,"placeholder":"10","disabled":""},model:{value:(_vm.form.details[index].quantityOrder),callback:function ($$v) {_vm.$set(_vm.form.details[index], "quantityOrder", _vm._n($$v))},expression:"form.details[index].quantityOrder"}})],1)]}},{key:"cell(quantityOutstanding)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('cleave',{key:index,staticClass:"form-control text-right form-control-sm",attrs:{"options":_vm.numeric,"placeholder":"10","disabled":""},model:{value:(_vm.form.details[index].quantityOutstanding),callback:function ($$v) {_vm.$set(_vm.form.details[index], "quantityOutstanding", _vm._n($$v))},expression:"form.details[index].quantityOutstanding"}})],1)]}},{key:"cell(quantity)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('validation-provider',{attrs:{"name":"quantity","rules":("required|" + (!_vm.actions.isPreview ? ("max_value:" + (_vm.form.details[index].quantityOutstanding)) : '')),"vid":("detail-" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{key:index,staticClass:"form-control text-right form-control-sm",attrs:{"state":errors.length > 0 ? false:null,"options":_vm.numeric,"placeholder":"10","disabled":_vm.actions.isPreview},model:{value:(_vm.form.details[index].quantity),callback:function ($$v) {_vm.$set(_vm.form.details[index], "quantity", _vm._n($$v))},expression:"form.details[index].quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]}}])}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.notes'),"label-for":"notes"}},[_c('b-form-textarea',{attrs:{"id":"notes","size":"sm","placeholder":!_vm.actions.isPreview ? _vm.$t('globalSingular.remark') : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1)],1),_c('div',{staticClass:"mt-3 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":_vm.actions.isPreview ? 'outline-secondary' : 'outline-primary',"to":{ name: 'apps-transactions-purchase-goods-recipient-list' }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.backToList'))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:" mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.delete'))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t('globalActions.update') : _vm.$t('globalActions.create'))+" ")],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }